// $(function() {
//   $.scrollUp({
//     scrollText: '',
//     scrollSpeed: 500
//   });
// });

var scrollDuration = 0;

$(function() {
  // 导航sticky
  // $('.navbar-fixed-top').sticky({
  //   topSpacing: 0,
  //   zIndex: 1000
  // });

  // 导航菜单
  $('#menu li.nav-item').click(function(e) {
    var offset = -parseInt($('#fullpage').css('marginTop'));
    var navanchor = $(e.currentTarget).data('navanchor');
    $('html').scrollTo($('#' + navanchor), scrollDuration, {offset: offset});
    $('#main-navbar-collapse').collapse('hide');
  });

  // 产品服务-导航菜单
  $('ul.nav-main a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
    // 删除旧的选中样式
    var thiz = $(this);
    $('ul.nav-sub a[data-toggle="tab"]').each(function(index) {
      if($(this) !== thiz) {
        $(this).parent().removeClass('active');
      }
    });
    var active = thiz.parent().hasClass('active');
    if(active) {
      var tabs = {
        '计算机视觉': '2D矩形',
        '自动驾驶': '点云标注',
        '语音文本': '语音标注',
        '工具定制': '标注工具',
      };
      var ariaControls = thiz.attr('aria-controls');
      var targetTab = $('#' + ariaControls + ' a[aria-controls="' + tabs[ariaControls] + '"]');
      targetTab.tab('show');
    }
  });

  $('.wechat-item-icon').tooltipster({
    // contentCloning: true,
    interactive: true,
  });

  // window窗口大小改变时，收起已展开的导航
  window.onresize = function(e) {
    $('#main-navbar-collapse').collapse('hide');
  };

  const swiper = new Swiper('.swiper', {
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    slidesPerView: 3,
    spaceBetween: 30,
    freeMode: true,
  });

});
